import axiosInstance from '../../api/axiosInstance';
import { LEGAL_DOC_MANAGEMENT_API_URL } from '../../api/Endpoints';
import { useAuth } from '../Authentication/AuthContext';
import AuthService from '../Authentication/AuthService';

const DOCAI_SERVICE_API = '/documents'
const FolderService = {
    
    GetFoldersByUser: async () => {
        try {       

            const userDetails = await AuthService.userProfile();
            const response = await axiosInstance.get(LEGAL_DOC_MANAGEMENT_API_URL + '/folderlistbyuserid', {
                params:
                    { ownerId:  userDetails.userId}
            });
            return response.data;
        } catch (error) {
            console.log('Error while getting folders : '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    createFolder: async (folderName) => {
        try {

            const userDetails = await AuthService.userProfile();
            const response = await axiosInstance.post(LEGAL_DOC_MANAGEMENT_API_URL + '/createfolder', {
                "id": 0,
                "name": folderName,
                //"2024-07-11T02:16:59.345Z"
                "createdAt": '2024-07-11T01:43:25.353',//new Date().toISOString(),
                "parentId": 1,
                "isDeleted": null,
                "owned_by": userDetails.userId
            });
            return response.data;
        } catch (error) {
            console.log('Error while creating folder : '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    getFilesByFolderId: async (folderId) => {
        try {

            const response = await axiosInstance.get(LEGAL_DOC_MANAGEMENT_API_URL + '/listbyfolderID', {
                params:
                    { folderId: folderId }
            });
            return response.data;
        } catch (error) {
            console.log('Error while getting files : '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    getFolderMetadata: async (folderId) => {
        try {
            const response = await axiosInstance.get(LEGAL_DOC_MANAGEMENT_API_URL + '/getfoldermetadata', {
                params: {
                    folderId: folderId
                }
            });
            return response.data;
        } catch (error) {
            console.log('Error while getting foldder metadata : '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    upateFolderMetadata: async (folderId, folderName) => {
        try {
            const response = await axiosInstance.post(LEGAL_DOC_MANAGEMENT_API_URL + '/updatefoldermetadata', null, {
                params: {
                    folderId: folderId,
                    name: folderName
                }
            });
            return response.data;
        } catch (error) {
            console.log('Error while updating folder metadata: '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    deleteFolder: async (folderId) => {
        try {
            const response = await axiosInstance.post(LEGAL_DOC_MANAGEMENT_API_URL + '/deletefolder', null, {
                params:
                    { documentId: folderId }
            });
            return response.data;
        } catch (error) {
            console.log('Error while deleting folder : '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    // ---------------------------- File Operations DO NOT MOVE ------------------------------------------------------------// 

    movefileToDifferentFolder: async (documentId, targetFolderId) => {
        try {
            const response = await axiosInstance.post(LEGAL_DOC_MANAGEMENT_API_URL + '/movedoc', null, {
                params:
                    { documentId: documentId, targetFolderId:targetFolderId }
            });
            return response.data;
        } catch (error) {
            console.log('Error while moving document to different folder : '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    deleteFile: async (documentId) => {
        try {
            const response = await axiosInstance.post(LEGAL_DOC_MANAGEMENT_API_URL + '/deletedoc', null, {
                params:
                    { documentId: documentId }
            });
            return response.data;
        } catch (error) {
            console.log('Error while deleting document : '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    getDocumentDetails: async (documentId) => {
        try {
            const response = await axiosInstance.post(LEGAL_DOC_MANAGEMENT_API_URL + '/getdocdetails', null, {
                params: {
                    documentId: documentId
                }
            });
            return response.data;
        } catch (error) {
            console.log('Error while getting document metadata : '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    uploadFile: async (formData, targetFolder, generatePreSignedUrl) => {
        try {
            const userDetails = await AuthService.userProfile();
            const response = await axiosInstance.post('https://c6j330ktjj.execute-api.us-east-1.amazonaws.com/TestlegalDochandling/uploaddoc', formData, {
                headers: { 
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    username : userDetails.username,
                    userId: userDetails.userId,
                    parentfolderid : targetFolder,
                    generatePreSignedUrl: generatePreSignedUrl,
                }
            });
            return response.data;
        } catch (error) {
            console.log('Error while uploading file: '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },
    uploadMultipleFiles: async (formData, targetFolder) => {
        try {
            const userDetails = await AuthService.userProfile();
            //https://c6j330ktjj.execute-api.us-east-1.amazonaws.com/TestlegalDochandling/uploadMultipledocs
            const response = await axiosInstance.post('https://c6j330ktjj.execute-api.us-east-1.amazonaws.com/TestlegalDochandling/uploadMultipledocs', formData, {
            //const response = await axiosInstance.post(LEGAL_DOC_MANAGEMENT_API_URL + '/uploadMultipledocs', formData, {
            
                headers: { 'Content-Type': 'multipart/form-data'},
                params: {
                    type: 'png',
                    username : userDetails.username,
                    parentfolderid : targetFolder
                }
            });
            return response.data;
        } catch (error) {
            console.log('Error while uploading file: '.error.response);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    },
    downloadFile: async (fileId) => {
        
        try {

            //http://docm3declegallb-1533086478.us-east-1.elb.amazonaws.com/api/documents/documents/getdoc
            const response = await axiosInstance.get('https://nnfbnmy7s1.execute-api.us-east-1.amazonaws.com/doc_dev/getdoc', {
            //const response = await axiosInstance.get(LEGAL_DOC_MANAGEMENT_API_URL + '/getdoc', {
            params:{
                id:fileId
            },
            //responseType: 'blob',
            //withCredentials: true,
          });
          return response;
        } catch (error) {
          console.error('Failed to download file', error.response);
          throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    previewDoc: async (fileId) => {
        
        try {
            const response = await axiosInstance.get(LEGAL_DOC_MANAGEMENT_API_URL + '/preview-doc', {
            params:{
                id:fileId
            },
            responseType: 'blob',
            withCredentials: true,
          });
          return response;
        } catch (error) {
          console.error('Failed to download file', error.response);
          throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    downloadMultipleFile: async (fileIds) => {
        
        try {
            // https://c6j330ktjj.execute-api.us-east-1.amazonaws.com/TestlegalDochandling/downloadmultipledocs
 
          //const response = await axiosInstance.get(LEGAL_DOC_MANAGEMENT_API_URL + '/downloadMultipleDocs', {
        const response = await axiosInstance.get('https://c6j330ktjj.execute-api.us-east-1.amazonaws.com/TestlegalDochandling/downloadmultipledocs', {
            params:{
                ids:fileIds
            },
            paramsSerializer: { indexes: null },
            responseType: 'blob',
            withCredentials: true,
          });
          return response;
        } catch (error) {
          console.error('Failed to download files', error.response);
          throw error.response ? error.response.data : new Error('Network Error');
        }
    },

    generateDocument: async (requestData) => {

        try {
          const userDetails = await AuthService.userProfile();
          console.log(userDetails);
          const response = await axiosInstance.post(LEGAL_DOC_MANAGEMENT_API_URL + '/generatedocument?prompt='+requestData+'&username='+userDetails.username+'&userId='+userDetails.userId, null,{
            //withCredentials: true,
          });
          return response;
    
        } catch (error) {
          console.error('Failed to generate the document', error.response);
          throw error.response ? error.response.data : new Error('Network Error');
        }
      },
};

export default FolderService;
