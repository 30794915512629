import React, { useState } from "react";
import {
    TextField,
    Typography,
    Box,
    Container,
    IconButton,
    Snackbar,
    Alert,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StyledButton from "../../Components/Folders/StyledButton";
import { ArrowBack } from "@mui/icons-material";

const LawyerForm = ({
    lawyerFormData,
    handleLawyerFormChange,
    onNavigateBack,
}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [loading, setLoading] = useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { firstName, lastName, email, description } = lawyerFormData;

        // Validate form inputs
        if (!firstName || !lastName || !email || !description) {
            setSnackbarMessage("Please fill out all fields.");
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
            return;
        }

        // Prepare the request payload
        const requestData = {
            firstName,
            lastName,
            email,
            description,
        };

        try {
            setLoading(true);

            // API Call
            const response = await fetch(
                "https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/requestlawyer",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                }
            );

            if (response.ok) {
                const responseData = await response.json();
                setSnackbarMessage(
                    "Your request has been submitted successfully!"
                );
                setSnackbarSeverity("success");
                setSnackbarOpen(true);

                // Optionally reset form and navigate back
                setTimeout(() => {
                    onNavigateBack();
                }, 3000);
            } else {
                const errorData = await response.json();
                setSnackbarMessage(
                    errorData.message || "Failed to submit request."
                );
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error submitting request:", error);
            setSnackbarMessage("Network error. Please try again later.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container
            sx={{
                p: 2,
                bgcolor: "white",
                borderRadius: 2,
                boxShadow: 3,
                maxWidth: "600px",
                margin: "auto",
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ pb: 2 }}
            >
                <IconButton
                    style={{
                        margin: "0 0 0px 0px",
                        width: "40px",
                        height: "40px",
                    }}
                    onClick={onNavigateBack}
                    edge="start"
                    color="inherit"
                    aria-label="back"
                >
                    <ArrowBack />
                </IconButton>
                <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    style={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        padding: "0px 10px 0px 10px",
                    }}
                >
                    Find me a lawyer
                </Typography>
            </Box>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                }}
            >
                <TextField
                    label="First Name"
                    name="firstName"
                    fullWidth
                    variant="outlined"
                    value={lawyerFormData.firstName}
                    onChange={handleLawyerFormChange}
                />
                <TextField
                    label="Last Name"
                    name="lastName"
                    fullWidth
                    variant="outlined"
                    value={lawyerFormData.lastName}
                    onChange={handleLawyerFormChange}
                />
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={lawyerFormData.email}
                    onChange={handleLawyerFormChange}
                />
                <TextField
                    label="Description of Problem"
                    name="description"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    value={lawyerFormData.description}
                    onChange={handleLawyerFormChange}
                />
                <Box display="flex" justifyContent="flex-end">
                    <StyledButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        disabled={loading}
                        sx={{
                            px: 3,
                            py: 1,
                            textTransform: "none",
                            width: "auto",
                            maxWidth: "200px",
                        }}
                    >
                        {loading ? "Submitting..." : "Submit"}
                    </StyledButton>
                </Box>
            </form>
            {/* Snackbar for messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default LawyerForm;
