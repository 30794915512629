//export const LEGAL_DOC_MANAGEMENT_API_URL = 'http://legalassistdocmanapilb2sp-1882103277.us-east-1.elb.amazonaws.com/api'
//export const LEGAL_DOC_MANAGEMENT_API_URL = 'https://1tri79sr8j.execute-api.us-east-1.amazonaws.com/Dev'

export const LEGAL_DOC_MANAGEMENT_API_URL = 'https://nnfbnmy7s1.execute-api.us-east-1.amazonaws.com/doc_dev'
//export const LEGAL_DOC_MANAGEMENT_API_URL = 'http://localhost:8089/api/documents/documents'

//export const LEGA_SEARCH_DOC_API_URL = 'http://legalassistsearchapilb3sp-1441591007.us-east-1.elb.amazonaws.com'
export const LEGA_SEARCH_DOC_API_URL = 'https://xchffq1pq2.execute-api.us-east-1.amazonaws.com/dev_deployment_search'
//https://xchffq1pq2.execute-api.us-east-1.amazonaws.com/dev_deployment_search/legalsearch
//export const USERMANAGEMENT_API_URL = 'http://lassistusrmgt2sep-1056336277.us-east-1.elb.amazonaws.com/api'
export const USERMANAGEMENT_API_URL = 'https://mtt2dp3ze6.execute-api.us-east-1.amazonaws.com/dev'

//http://lassistusrmgt2sep-1056336277.us-east-1.elb.amazonaws.com/api/UserManagement/swagger-ui/index.html#/auth-controller/login 
// 4th Sept User Management - lassistusrmgt2sep-1056336277.us-east-1.elb.amazonaws.com 
// 4th Sept Doc Man legalassistdocmanapilb2sp-1882103277.us-east-1.elb.amazonaws.com
// 4th Sept Search legalassistsearchapilb3sp-1441591007.us-east-1.elb.amazonaws.com
// 4th Sept Doc AI ML - Doc Summary  lassistui1septlb-1510671865.us-east-1.elb.amazonaws.com
// 4th Sept Legal Assist UI - lassistui2sept-275833679.us-east-1.elb.amazonaws.com
//export const LEGAL_ASSIST_AI_NLP_SERVICE_API_URL = 'http://legalassistolanurainlp-1275429653.us-east-1.elb.amazonaws.com'
export const LEGAL_ASSIST_AI_NLP_SERVICE_API_URL = 'https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev'
//export const LEGAL_ASSIST_AI_NLP_SERVICE_API_URL = 'http://lassistainlp2oct-1492182506.us-east-1.elb.amazonaws.com'
//http://lassistui1septlb-1510671865.us-east-1.elb.amazonaws.com/operations/generatesummary?summary_type=Summary
//http://lassistainlp2oct-1492182506.us-east-1.elb.amazonaws.com/docs