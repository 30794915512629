import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, IconButton,
    Typography, Toolbar, Box, Button, MenuItem, Menu, TextField, Pagination, Select, FormControl, InputLabel,
    InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Alert,
    ListItem,
    List,
    Grid,
    Divider,
    Stack
} from '@mui/material';
import {
    MoreVert, Delete, Edit, FileCopy, MoveToInbox, Info, ArrowBack, Download, Search, Delete as DeleteIcon,
    Folder,
    Upload,
    CloudUpload,
    DeleteOutline,
    Summarize,
    ReadMore
} from '@mui/icons-material';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import FolderService from '../../Services/DocAI/FolderService';
import './FolderDetails.css';
import FolderIcon from '@mui/icons-material/Folder';
import StyledButton from './StyledButton';
import UploadDocumentDialog from './UploadDialogDocument';
import FileInformationDialog from './FileInformationDialog';

const FileTable = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentFile, setCurrentFile] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(1);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [moveDialogOpen, setMoveDialogOpen] = useState(false);
    const [confirmMoveOpen, setConfirmMoveOpen] = useState(false);

    const [newFileName, setNewFileName] = useState('');
    const [renameError, setRenameError] = useState('');

    const [moveTargetFolder, setMoveTargetFolder] = useState(null);
    const [moveError, setMoveError] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');

    const { folderId } = useParams();
    const [folderName, setFolderName] = useState(null);
    const [folders, setFolders] = useState([]);

    const [fileInfoDialogOpen, setFileInfoDialogOpen] = useState(false);

    const navigate = useNavigate();

    const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);

    const handleUploadFileOpenDialog = () => {
        setUploadFileDialogOpen(true);
    };

    const handleUploadFileCloseDialog = () => {
        setUploadFileDialogOpen(false);
    };

    const handleUpload = (files) => {
        // Handle the uploaded files here
        console.log('Uploaded files:', files);
        fetchFiles(folderId);
    };


    useEffect(() => {
        fetchFiles(folderId);
        fetchFolders()
        getFOlderMetadata(folderId);
    }, []);

    const fetchFiles = async (folderId) => {
        try {
            // Replace with your API endpoint
            const response = await FolderService.getFilesByFolderId(folderId);
            setFiles(response);

        } catch (error) {
            console.error('Error fetching folders:', error);
        }
    };

    const fetchFolders = async () => {
        try {
            // Replace with your API endpoint
            const response = await FolderService.GetFoldersByUser();
            //setFolders(folders); // Adjust according to your API response structure
            setFolders(response);

        } catch (error) {
            console.error('Error fetching folders:', error);
        }
    };

    const getFOlderMetadata = async (folderId) => {
        const response = await FolderService.getFolderMetadata(folderId);
        setFolderName(response.name);

    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectedFiles(files.map((file) => file.id));
        } else {
            setSelectedFiles([]);
        }
    };

    const handleSelectFile = (event, id) => {
        const selectedIndex = selectedFiles.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedFiles, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedFiles.slice(1));
        } else if (selectedIndex === selectedFiles.length - 1) {
            newSelected = newSelected.concat(selectedFiles.slice(0, -1));
        } else {
            newSelected = newSelected.concat(
                selectedFiles.slice(0, selectedIndex),
                selectedFiles.slice(selectedIndex + 1),
            );
        }

        setSelectedFiles(newSelected);
    };

    const handleActionClick = (event, selectedFile) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCurrentFile(selectedFile);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentFile(null);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(1); // Reset to the first page after a search
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleDownloadSelected = async () => {
        if (selectedFiles.length === 0) {
            setSnackbarMessage('Please select at least one file to download');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }
        try {
            const response = await FolderService.downloadMultipleFile(selectedFiles);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip'); // Assuming the backend returns a zip file
            document.body.appendChild(link);
            link.click();
            link.remove();

            setSnackbarMessage('Files downloaded successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

        } catch (error) {
            console.error('Error downloading files:', error);
            setSnackbarMessage('Failed to download files');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleDownloadFile = async () => {
        if (!currentFile) {
            return;
        }

        try {
            const response = await FolderService.downloadFile(currentFile.id);

            const presignedUrl = response.data;
            const link = document.createElement('a');
            link.href = presignedUrl;
            link.setAttribute('download', getDocNameFromUrl(currentFile.documentUrl)); // Extract the document name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);


            // console.log(response.data);
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', getDocNameFromUrl(currentFile.documentUrl));
            // document.body.appendChild(link);
            // link.click();
            // link.remove();
            handleCloseMenu();
            setSnackbarSeverity('success');
            setSnackbarMessage('File downloaded successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error downloading file:', error);
            handleCloseMenu();
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to download file');
            setSnackbarOpen(true);
        } finally {

        }
    };

    // Function to handle showing file details (You'll need to add a modal or dialog for details display)
    const handleShowFileDetails = () => {
        setFileInfoDialogOpen(true);
        setAnchorEl(null);
    };

    const handleFileInfoDialogClose = () => {
        setFileInfoDialogOpen(false);
        setCurrentFile({});
    };

    // Function to handle deleting a file
    const handleDeleteFile = () => {
        setDeleteDialogOpen(true);
    };

    const confirmDeleteFile = () => {
        if (currentFile) {
            // axios
            //     .delete(`/api/files/${selectedFile.id}`)
            //     .then(() => {
            //         setSnackbarMessage(`File "${selectedFile.name}" deleted successfully.`);
            //         setSnackbarOpen(true);
            //         // Refresh file list or perform other necessary actions
            //     })
            //     .catch((error) => {
            //         setSnackbarMessage(`Failed to delete file: ${error.message}`);
            //         setSnackbarOpen(true);
            //     })
            //     .finally(() => {
            //         handleDialogClose();
            //     });
            try {
                const resposne = FolderService.deleteFile(currentFile.id);
                setFiles(files.filter(f => f.id !== currentFile.id));
                setSnackbarSeverity('success');
                setSnackbarMessage(`File "${getDocNameFromUrl(currentFile.documentUrl)}" deleted successfully.`);
                setSnackbarOpen(true);
                setDeleteDialogOpen(false);
                handleCloseMenu();
            } catch (error) {
                setSnackbarSeverity('fail');
                setSnackbarMessage(`Failed to delete file: ${error.message}`);
                setSnackbarOpen(true);
                console.error('Error deleting file:', error);
                setDeleteDialogOpen(false);
                handleCloseMenu();
            } finally {

            }
        }
    };

    const cancelDeleteFile = () => {
        setDeleteDialogOpen(false);
        handleCloseMenu();
    };

    const handleRenameClick = () => {
        setRenameError('');
        setNewFileName(getDocNameFromUrl(currentFile.documentUrl));
        setRenameDialogOpen(true);
    };

    const handleSummarizeDocumentClick = () => {
        navigate(`/summarize-doc?file=${currentFile.id}&mode=1`);
    };

    const handlePreviewDocumentClick = () => {
        navigate(`/summarize-doc?file=${currentFile.id}&mode=0`);
    };

    const handleRenameFile = () => {
        if (!newFileName.trim()) {
            setRenameError('File name cannot be empty.');
            return;
        }

        if (newFileName.length > 255) { // Example length limit
            setRenameError('File name is too long.');
            return;
        }

        const specialCharPattern = /[<>:"/\\|?*]/;
        if (specialCharPattern.test(newFileName)) {
            setRenameError('File name contains invalid characters.');
            return;
        }

        // Clear any previous errors
        setRenameError('');

        // Make API call to rename file
        axios.put(`/api/files/${currentFile.id}`, { name: newFileName })
            .then(() => {
                setFiles(files.map(f => (f.id === currentFile.id ? { ...f, name: newFileName } : f)));
                setRenameDialogOpen(false);
                setSnackbarMessage('File renamed successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch(error => {
                console.error('Error renaming file:', error);
                setRenameError('An error occurred while renaming the file.');
            });
    };

    const handleMoveClick = () => {
        setMoveTargetFolder(null);
        setMoveDialogOpen(true);
        setAnchorEl(null);
    };

    const handleMoveFolderSelect = (e, folder) => {
        setMoveTargetFolder(folder);
        setMoveError(''); // Clear previous errors
    };

    const handleMoveDocument = () => {
        if (!moveTargetFolder) {
            setMoveError('Please select a target folder.');
            return;
        }
        setConfirmMoveOpen(true);
    };

    const handleConfirmMove = () => {
        // Make API call to move file(s)
        setConfirmMoveOpen(false);
        try {
            const response = FolderService.movefileToDifferentFolder(currentFile.id, moveTargetFolder.id);
            setFiles(files.filter(f => !selectedFiles.includes(f.id)));
            //fetchFiles(folderId);
            setFiles(files.filter(f => currentFile.id !== f.id));
            setMoveDialogOpen(false);
            setConfirmMoveOpen(false);
            setSnackbarMessage('Files moved successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setSelectedFiles([]); // Clear selection after moving files
            setMoveTargetFolder(null)
            setCurrentFile({})
            console.log('File Moved Successfully');
        } catch (error) {
            console.error('Error moving files:', error);
            setMoveError('An error occurred while moving the files.');
            setSnackbarMessage('An error occurred while moving the files.');
            setSnackbarSeverity('fail')
            setSnackbarOpen(true);
            setMoveTargetFolder(null)
        } finally {
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const filteredFiles = files && files.filter((file) =>
        file.documentUrl.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedFiles = fetchFiles && filteredFiles.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const isSelected = (id) => selectedFiles.indexOf(id) !== -1;

    function getDocNameFromUrl(docUrl) {
        var n = ("" + docUrl).lastIndexOf('/') + 1;
        return ("" + docUrl).substring(n);
    }

    return (
        <Box flex className="file-details">
            <Box>

                <Toolbar>
                    <div style={{ width: '40px', height: '40px' }}>
                        <IconButton onClick={() => navigate(-1)} edge="start" color="inherit" aria-label="back">
                            <ArrowBack />
                        </IconButton>
                    </div>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {folderName}
                    </Typography>
                </Toolbar>
                <Divider component="div" />
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search files"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Grid spacing={2} container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Grid item>
                            {selectedFiles.length > 0 && (

                                <StyledButton
                                    variant="contained"
                                    sx={{ background: '#4a0f73', maxWidth: '300px' }}
                                    startIcon={<Download />}
                                    onClick={handleDownloadSelected}
                                >
                                    Download
                                </StyledButton>
                            )}
                        </Grid>

                        <Grid item>
                            <StyledButton
                                variant="contained"
                                sx={{ background: '#4a0f73', }}
                                startIcon={<CloudUpload />}
                                style={{ marginBottom: 16 }}
                                onClick={handleUploadFileOpenDialog}
                            >
                                Upload Document
                            </StyledButton>

                        </Grid>
                    </Grid>
                </Toolbar>
                <UploadDocumentDialog
                    open={uploadFileDialogOpen}
                    onClose={handleUploadFileCloseDialog}
                    onUpload={handleUpload}
                    folderId={folderId}
                />
                {/* File Information Dialog */}
                {currentFile && <FileInformationDialog
                    open={fileInfoDialogOpen}
                    onClose={handleFileInfoDialogClose}
                    file={currentFile}
                    fileName={currentFile && getDocNameFromUrl(currentFile.documentUrl)}
                />}
                <Box mt={2} mb={2}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selectedFiles.length > 0 && selectedFiles.length < files.length}
                                            checked={files.length > 0 && selectedFiles.length === files.length}
                                            onChange={handleSelectAllClick}
                                        />
                                    </TableCell>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>File Type</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Modified Date</TableCell>
                                    <TableCell>Owner</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedFiles.map((file) => {
                                    const isItemSelected = isSelected(file.id);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleSelectFile(event, file.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={file.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    onChange={(event) => handleSelectFile(event, file.id)}
                                                />
                                            </TableCell>
                                            <TableCell>{getDocNameFromUrl(file.documentUrl)}</TableCell>
                                            <TableCell>{file.documentType}</TableCell>
                                            <TableCell>{file.createdAt}</TableCell>
                                            <TableCell>{file.updatedAt}</TableCell>
                                            <TableCell>{file.uploadedBy}</TableCell>
                                            <TableCell>
                                                <div style={{ width: '40px', height: '40px' }}>
                                                    <IconButton onClick={(event) => { event.stopPropagation(); event.preventDefault(); handleActionClick(event, file) }}>
                                                        <MoreVert size={20} />
                                                    </IconButton>
                                                </div>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={2} mx={2} display="flex" justifyContent="flex-end">
                        <FormControl variant="outlined" size="small" sx={{ width: '55px' }}>
                            <InputLabel>Rows per page</InputLabel>
                            <Select
                                value={rowsPerPage}
                                onChange={handleRowsPerPageChange}
                                label="Rows per page"
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </FormControl>
                        <Pagination
                            count={Math.ceil(filteredFiles.length / rowsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                        />
                    </Box>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={handleDownloadFile}>
                        <ListItemIcon>
                            <Download fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Download" />
                    </MenuItem>
                    <MenuItem onClick={handleRenameClick}>
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Rename" />
                    </MenuItem>
                    <MenuItem onClick={handlePreviewDocumentClick}>
                        <ListItemIcon>
                            <ReadMore fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Preview Document" />
                    </MenuItem>
                    <MenuItem onClick={handleSummarizeDocumentClick}>
                        <ListItemIcon>
                            <ReadMore fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Summarize Document" />
                    </MenuItem>
                    <MenuItem onClick={handleMoveClick}>
                        <ListItemIcon>
                            <MoveToInbox fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Move to Another Folder" />
                    </MenuItem>
                    <MenuItem onClick={handleShowFileDetails}>
                        <ListItemIcon>
                            <Info fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Show Details" />
                    </MenuItem>
                    <MenuItem onClick={handleDeleteFile}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </MenuItem>
                </Menu>

                {/* Rename File Dialog */}
                <Dialog open={renameDialogOpen} onClose={() => setRenameDialogOpen(false)}>
                    <DialogTitle>Rename File</DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="fileName"
                            label="New File Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newFileName}
                            onChange={(e) => setNewFileName(e.target.value)}
                            error={!!renameError}
                            helperText={renameError}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setRenameDialogOpen(false)} color="primary">Cancel</Button>
                        <Button onClick={handleRenameFile} color="primary">Rename</Button>
                    </DialogActions>
                </Dialog>

                {/* Move Document Dialog */}
                <Dialog open={moveDialogOpen} onClose={() => setMoveDialogOpen(false)}>
                    <DialogTitle>Move Document</DialogTitle>
                    <DialogContent dividers>
                        <List>
                            {folders.map((folder) => (
                                <ListItem
                                    key={folder.id}
                                    button
                                    selected={moveTargetFolder && moveTargetFolder.id === folder.id}
                                    onClick={(e) => handleMoveFolderSelect(e, folder)}
                                >
                                    <ListItemIcon>
                                        <Folder />
                                    </ListItemIcon>
                                    <ListItemText primary={folder.name} />
                                </ListItem>
                            ))}
                        </List>
                        {moveError && <Typography color="error">{moveError}</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setMoveDialogOpen(false)} color="primary">Cancel</Button>
                        <Button onClick={handleMoveDocument} color="primary">Move</Button>
                    </DialogActions>
                </Dialog>

                {/* Confirm Move Dialog */}
                <Dialog open={confirmMoveOpen} onClose={() => setConfirmMoveOpen(false)}>
                    <DialogTitle>Confirm Move</DialogTitle>
                    <DialogContent dividers>
                        <Typography>
                            Are you sure you want to move the selected file(s) to the selected folder?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmMoveOpen(false)} color="primary">Cancel</Button>
                        <Button onClick={handleConfirmMove} color="primary">Confirm Move</Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Confirmation Dialog */}
                <Dialog open={deleteDialogOpen} onClose={cancelDeleteFile}>
                    <DialogTitle>
                    <Stack alignItems="center" direction="row" gap={2}>
                        <DeleteOutline/>
                        <Typography variant="body1">Confirm Delete</Typography>
                    </Stack>
                    </DialogTitle>
                    <DialogContent dividers>Are you sure you want to delete this file?</DialogContent>
                    <DialogActions>
                        <Button onClick={cancelDeleteFile} color="primary">
                            Cancel
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <Button onClick={confirmDeleteFile} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbar for notifications */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default FileTable;
