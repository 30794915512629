import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, InputGroup, Row, Col, Spinner } from 'react-bootstrap';
import '../Authentication/auth-form.css';
import './Signup.css'
import { useAuth } from '../Authentication/AuthContext';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { Alert, Snackbar } from '@mui/material';

function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [authForm, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: '',
    businessOwnerName: '',
  });

  const [showBusinessOwner, setShowBusinessOwner] = useState(false);
  const { register, error } = useAuth();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setShowPassword(prev => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(prev => !prev);

  const handleChange = (e) => {
    setFormData({ ...authForm, [e.target.name]: e.target.value });
  };

  const handleRoleChange = (e) => {
    const { checked } = e.target;
    setFormData({ ...authForm, role: checked ? 'lawyer' : 'user' });
    setShowBusinessOwner(checked);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    setIsLoading(true);
    try {
      await register(authForm.username, authForm.email, authForm.password, authForm.firstName, authForm.lastName);
      setMessage("A confirmation link has been sent to your email. Please confirm your registration.");
      setShowSnackbar(true);
      setTimeout(() => {
        navigate('/');
      }, 10000);

    } catch (error) {
      setMessage('Registration failed: ' + error.response?.data?.message || 'An error occurred');
      setShowSnackbar(true);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
    <Form className='' noValidate validated={validated} onSubmit={handleSignup}>
      <div className='text-center text-light'>
        <h4 className='py-2 radius-card'>Sign Up</h4>
      </div>
      <Row className="gx-1"> {/* Reduce horizontal gap */}
        <Col md={6}>
          <Form.Group controlId="firstName">
            <Form.Control
              type="text"
              name="firstName"
              placeholder="First Name"
              value={authForm.firstName}
              onChange={handleChange}
              required
              isInvalid={validated && authForm.firstName.trim() === ''}
            />
            <Form.Control.Feedback type="invalid" style={{background:"red", color:"white"}}>
              Please enter your first name.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastName">
            <Form.Control
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={authForm.lastName}
              onChange={handleChange}
              required
              isInvalid={validated && authForm.lastName.trim() === ''}
            />
            <Form.Control.Feedback type="invalid" style={{background:"red", color:"white"}}>
              Please enter your last name.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="username">
        {error && <p>{error.message}</p>}
        <Form.Control
          placeholder='Username'
          type="text"
          name="username"
          value={authForm.username}
          onChange={handleChange}
          pattern="^[a-zA-Z0-9]+$"
          required
          isInvalid={validated && !/^[a-zA-Z0-9]+$/.test(authForm.username)}
        />
        <Form.Control.Feedback tooltip className="invalid-feedback" type="invalid" style={{background:"red", color:"white"}}>
          Please enter a valid username.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Control
          type="email"
          name="email"
          placeholder='Your professional email'
          value={authForm.email}
          onChange={handleChange}
          required
          isInvalid={validated && !/^\S+@\S+\.\S+$/.test(authForm.email)}
        />
        <Form.Control.Feedback type="invalid" style={{ paddingLeft: '35px', background:"red", color:"white" }}>
          Please enter a valid email address.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="password">
        <InputGroup>
          <Form.Control
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder='Password'
            value={authForm.password}
            onChange={handleChange}
            required
            isInvalid={validated && !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,16}$/.test(authForm.password)}
          />
          <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
            {showPassword ? <EyeSlashFill color='#4a0f73' /> : <EyeFill color='#4a0f73' />}
          </InputGroup.Text>
          <Form.Control.Feedback type="invalid" style={{background:"red", color:"white"}}>
            Password must be at least 6 characters long, contain at least one number, one uppercase letter, and one special character (!@#$%^&*).
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group controlId="confirmPassword">
        <InputGroup>
          <Form.Control
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder='Confirm Password'
            value={authForm.confirmPassword}
            onChange={handleChange}
            required
            isInvalid={validated && authForm.confirmPassword !== authForm.password}
          />
          <InputGroup.Text onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
            {showConfirmPassword ? <EyeSlashFill color='#4a0f73' /> : <EyeFill color='#4a0f73' />}
          </InputGroup.Text>
          <Form.Control.Feedback type="invalid" style={{background:"red", color:"white"}}>
            Passwords do not match.
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.Switch
          className='text-light'
          label="I am a Lawyer"
          name="role"
          onChange={handleRoleChange}
          checked={showBusinessOwner}
          style={{color:"white"}}
        />
        {showBusinessOwner && (
          <Form.Group controlId="formBusinessOwnerName">
            <Form.Control
              type="text"
              placeholder="Enter business owner name"
              name="businessOwnerName"
              value={authForm.businessOwnerName}
              onChange={handleChange}
            />
          </Form.Group>
        )}
      </Form.Group>

      <Button type='submit' disabled={isLoading}>

      {isLoading ? <Spinner animation="border" size="sm" /> :
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-fill-check" viewBox="0 0 20 20">
          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
          <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
        </svg> } Sign Up
      </Button>

      {message && <div>{message}</div>}
    </Form>

    <Snackbar
        open={showSnackbar}
        autoHideDuration={10000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={message.includes("confirmation link has been sent") ? "success" : "error"}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      </>
  );
}

export default Signup;
