import { styled } from '@mui/system';
import { Paper, Card, Box, Typography, AccordionSummary, AccordionDetails } from '@mui/material';

export const CustomAccordionSummary = styled(({ expanded, ...props }) => (
  <AccordionSummary {...props} />
))(({ theme, expanded }) => ({
  backgroundColor: '#320d52',
  color: '#ffffff',
  borderRadius: '10px 10px 0 0',
  padding: '0px 0px',
  boxShadow: !expanded ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '0px 2px 4px rgba(0, 0, 0, 0.1)',
  border: !expanded ? 'none' : '1px solid rgba(0, 0, 0, 0.2)',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#ffffff',
  },
}));

export const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: '#4a0f733b',
  color: '#ffffff',
  borderRadius: '0px 0px 10px 10px',
  padding: '15px',
  maxHeight: '300px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
}));

export const ChatBubble = styled(Paper)(({ isUser }) => ({
  backgroundColor: isUser ? '#dcf8c6' : '#edf2f7',
  color: isUser ? '#2d3748' : '#2d3748',
  padding: '10px 15px',
  borderRadius: '20px',
  margin: '10px',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  maxWidth: '70%',
  width: 'fit-content',
  wordBreak: 'break-word',
}));

export const ChatContainer = styled(Card)(({ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 100px)', overflowY: 'hidden' }));

export const ChatContent = styled(Box)(({ flex: 1, overflowY: 'auto', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }));

export const ChatInputContainer = styled(Box)(({ padding: '10px', backgroundColor: '#f7fafc', borderTop: '1px solid #e2e8f0' }));

export const ContactCard = styled(Card)(({ display: 'flex', alignItems: 'center', padding: '10px', marginBottom: '10px', backgroundColor: '#4a0f7361', borderRadius: '10px', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }));

export const ContactImage = styled('img')({
  width: '80px',
  height: '80px',
  borderRadius: '50%',
  marginRight: '15px',
});

export const ContactDetails = styled(Box)({
  flex: 1,
});

export const CustomAccordionItem = styled(Typography)(({ theme }) => ({
  backgroundColor: '#4a0f7361',
  borderRadius: '10px',
  marginBottom: '5px',
  padding: '15px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  '&:last-child': {
    marginBottom: 0,
  },
}));
