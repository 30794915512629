import React, { useState, useEffect, useRef } from 'react';
import { Grid, TextField, Card, Snackbar, Box, CircularProgress, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, Alert, Button, IconButton, Tooltip } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import { styled } from '@mui/system';
import DocAIService from '../../Services/DocAI/DocAIService';
import StyledButton from '../../Components/Folders/StyledButton';
import { PencilSquare, SendArrowUpFill } from 'react-bootstrap-icons';
import { PersonSearch } from '@mui/icons-material';
import LawyerForm from './LawyerForm';
import {
  CustomAccordionSummary,
  CustomAccordionDetails,
  ChatBubble,
  ChatContainer,
  ChatContent,
  ChatInputContainer
} from './Styles';

function LegalQueryAssistant() {
  const [query, setQuery] = useState('');
  const [sessionIdCurrent, setSessionIdCurrent] = useState();
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // Snackbar severity ('success', 'error', 'warning', 'info')
  const [lawyers, setLawyers] = useState([]);
  const chatEndRef = useRef(null);
  const [expandedPanel, setExpandedPanel] = useState('panel2');
  const [chatHistoryTitle, setChatHistoryTitle] = useState({
    today: [],
    previous7Days: [],
    previous30Days: [],
  });

  const [showLawyerForm, setShowLawyerForm] = useState(false);
  const [lawyerFormData, setLawyerFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    description: '',
  });


  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };


  const handleValidation = () => {
    if (!query.trim()) {
      showSnackbar('Please enter your query.', 'warning');
      return false;
    }
    return true;
  };

  const startSession = async () => {
    try {
      const data = await DocAIService.startSession("legal_assistant");
      setSessionIdCurrent(data.sessionId);
      await fetchChatSessions();
      return data.sessionId;
    } catch (error) {
      console.error('Error starting session:', error);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  };

  const handleNewChat = async () => {
    try {
      setLoading(true);
      setShowLawyerForm(false);
      setLawyerFormData({ firstName: '', lastName: '', email: '', description: '' });
      const newSessionId = await startSession();
      setChatHistory([]);
      setSessionIdCurrent(newSessionId);
      showSnackbar('New chat session created', 'success');
    } catch (error) {
      showSnackbar('Error creating new chat session. Please try again.', 'error');
      console.error('Error creating new chat session.', error);
    } finally {
      setLoading(false);
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!handleValidation()) return;

    setLoading(true);
    const userMessage = { message: query, isUser: true };
    setChatHistory([...chatHistory, userMessage]);

    try {
      let currentSessionId = sessionIdCurrent;
      if (!currentSessionId) {
        currentSessionId = await startSession();
      }

      const apiResponse = await DocAIService.sendMessage(currentSessionId, query);
      const responseMessage = { message: apiResponse.response, isUser: false };

      setChatHistory((prevHistory) => [...prevHistory, responseMessage]);
      setLawyers([
        {
          name: 'Test 1, Test2',
          contact: 'test@example.com',
          specialization: 'Criminal Law',
          description: 'Expert in criminal defense and legal representation.',
          photo: 'https://via.placeholder.com/150',
        },
        {
          name: 'Test 1, Test2',
          contact: 'test@example.com',
          specialization: 'Family Law',
          description: 'Specializes in divorce, custody, and family-related legal matters.',
          photo: 'https://via.placeholder.com/150',
        },
      ]);
    } catch (error) {
      showSnackbar('Error fetching response. Please try again later.', 'error');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setQuery('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit(event);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      document.getElementById('chat-window').style.height = `${window.innerHeight - 100}px`; // Updated height calculation
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }, [chatHistory]);


  const fetchChatSessions = async () => {
    try {

      const data = await DocAIService.listSessions();

      const sessions = data.sessions || [];
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);

      const categorizedSessions = {
        today: [],
        previous7Days: [],
        previous30Days: [],
      };
      sessions.forEach((session) => {
        const timestamp = session.latestTimestamp ? new Date(session.latestTimestamp) : null;

        if (timestamp) {
          if (timestamp.toDateString() === today.toDateString()) {
            categorizedSessions.today.push(session);
          } else if (timestamp >= sevenDaysAgo) {
            categorizedSessions.previous7Days.push(session);
          } else if (timestamp >= thirtyDaysAgo) {
            categorizedSessions.previous30Days.push(session);
          } else {
            categorizedSessions.previous30Days.push(session);
          }
        } else {
          categorizedSessions.previous30Days.push(session);
        }
      });

      Object.keys(categorizedSessions).forEach((key) => {
        categorizedSessions[key] = categorizedSessions[key].sort((a, b) => {
          const timeA = new Date(a.latestTimestamp || 0).getTime();
          const timeB = new Date(b.latestTimestamp || 0).getTime();
          return timeB - timeA; // Sort descending
        });
      });
      setChatHistoryTitle([]);
      setChatHistoryTitle(categorizedSessions);
      renderSessions(categorizedSessions.today, 'Today');
      renderSessions(categorizedSessions.previous7Days, 'Previous 7 Days');
      renderSessions(categorizedSessions.previous30Days, 'Previous 30 Days');
    } catch (error) {
      console.error("Error fetching chat history titles", error);
    }
  };


  const fetchChatHistory = async (sessionId) => {
    try {

      setShowLawyerForm(false);
      setLawyerFormData({ firstName: '', lastName: '', email: '', description: '' });

      console.log("Session id in fetch history: " + sessionId);
      const sessionDetails = await DocAIService.getSession(sessionId);
      const { interactions } = sessionDetails;
      setChatHistory([]);
      if (interactions?.length > 0) {
        const formattedHistory = interactions.flatMap((interaction) => [
          { message: interaction.user_message, isUser: true },
          { message: interaction.bot_response, isUser: false },
        ]);

        setChatHistory(() => [...formattedHistory]);
        setSessionIdCurrent(sessionId);
      } else {
        setChatHistory([]);
      }
    } catch (error) {
      console.error("Error fetching session details:", error);
      setChatHistory([]);
    }
  };


  useEffect(() => {
    fetchChatSessions();
  }, []);

  const handleLawyerFormChange = (e) => {
    const { name, value } = e.target;
    setLawyerFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmitLawyerForm = (e) => {
    e.preventDefault();

    const { firstName, lastName, email, description } = lawyerFormData;
    if (!firstName || !lastName || !email || !description) {
      showSnackbar('Please fill out all fields.', 'warning');
      return;
    }

    showSnackbar('Your request has been submitted successfully! You will be redirect to chat window.', 'success');
    setTimeout(() => {
      setLawyerFormData({ firstName: '', lastName: '', email: '', description: '' });
      setShowLawyerForm(false);
    }, 5000);
  };

  const handleNavigateBack = () => {
    setShowLawyerForm(false);
    setLawyerFormData({ firstName: '', lastName: '', email: '', description: '' });
  };

  const renderSessions = (sessions, label) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="p" sx={{ pb: 1, mb: 0 }}>
        {label}
      </Typography>
      {sessions && sessions.length > 0 ? (
        sessions.map((session) => (
          <Box
            key={session.sessionId}
            sx={{
              padding: '10px',
              backgroundColor: session.sessionId === sessionIdCurrent ? '#320d52' : '#a099a461',
              color: session.sessionId === sessionIdCurrent ? '#ffffff' : '#ffffff',
              borderRadius: '5px',
              marginBottom: '5px',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: session.sessionId === sessionIdCurrent ? '' : '#8f8f8f',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transform: 'scale(1.02)',
              },
            }}
            onClick={() => fetchChatHistory(session.sessionId)}
          >
            <Typography variant="body1">{session.title || session.sessionId}</Typography>
          </Box>
        ))
      ) : (
        <Typography sx={{
          padding: '10px',
          backgroundColor: '#a099a461',
          color: '#ffffff',
          borderRadius: '5px',
          marginBottom: '5px',
          cursor: 'pointer',
          // transition: 'all 0.3s ease',
          '&:hover': {
            // backgroundColor: '#8f8f8f',
            // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
        }}>No chats found</Typography>
      )}
    </Box>
  );

  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} md={4}>
          <Accordion defaultExpanded expanded sx={{ background: 'transparent', borderRadius: '10px' }}>
            <CustomAccordionSummary
              aria-controls="chat-history-title-content"
              id="chat-history-title-header"
              expanded={true}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'center',
                    color: 'white',
                    paddingLeft: '15px',
                  }}
                >
                  Chat History
                </Typography>
                <IconButton
                  onClick={handleNewChat}
                  title=''
                  sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: 'transparent',
                    marginRight: '15px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  <Tooltip title="New Chat">
                    <PencilSquare style={{ color: 'white', fontSize: 24 }} />
                  </Tooltip>
                </IconButton>
              </Box>

            </CustomAccordionSummary>
            <CustomAccordionDetails>
              <Box>
                {renderSessions(chatHistoryTitle.today, 'Today')}
                {renderSessions(chatHistoryTitle.previous7Days, 'Previous 7 Days')}
                {renderSessions(chatHistoryTitle.previous30Days, 'Previous 30 Days')}
              </Box>
            </CustomAccordionDetails>
          </Accordion>
          <Box mt={2}>
            <StyledButton
              variant="contained"
              sx={{ background: '#198754' }}
              startIcon={<PersonSearch />}
              style={{}}
              onClick={() => setShowLawyerForm(true)}
            >
              Find me a lawyer.
            </StyledButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>

          {showLawyerForm ? (
            <Box style={{ borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "" }}>
              <LawyerForm
                lawyerFormData={lawyerFormData}
                handleLawyerFormChange={handleLawyerFormChange}
                onSubmitLawyerForm={onSubmitLawyerForm}
                onNavigateBack={handleNavigateBack}
              />
            </Box>
          ) : (

            <ChatContainer id="chat-window">
              {chatHistory.length === 0 ? (<Typography
                variant="h4"
                color="textSecondary"
                align="center"
                sx={{
                  mt: 3,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%', // Ensures it takes full height of parent
                }}
              >
                Enter a prompt to start chatting
              </Typography>) : (

                <ChatContent>
                  {chatHistory.length === 0 ? (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                      sx={{
                        mt: 3,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%', // Ensures it takes full height of parent
                      }}
                    >
                      Enter a prompt to start chatting
                    </Typography>
                  ) : (
                    chatHistory.map((chat, index) => {
                      if (!chat) {
                        window.alert(chat.message); // Trigger an alert for bot messages
                      }
                      return (<ChatBubble key={index} isUser={chat.isUser}>
                        {chat.isUser ? (
                          <Typography variant="body1">{chat.message}</Typography>
                        ) : (



                          <TypeAnimation
                            key={`${chat.message}-${index}`} // Add unique key
                            sequence={[chat.message, 3000]}
                            wrapper="span"
                            speed={{ type: "keyStrokeDelayInMs", value: 10 }}
                            omitDeletionAnimation
                            cursor={false}
                          />
                        )}
                      </ChatBubble>)
                    })
                  )}
                  {/* Keep this div for scrolling */}
                  <div ref={chatEndRef} />
                </ChatContent>)}
              <ChatInputContainer>
                <form onSubmit={onSubmit} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label="Type your message..."
                    multiline
                    rows={1}
                    fullWidth
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    variant="outlined"
                    InputLabelProps={{ style: { color: '#4a5568' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#e2e8f0',
                        },
                        '&:hover fieldset': {
                          borderColor: '#90cdf4',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#3182ce',
                        },
                      },
                    }}
                  />
                  <Box ml={3}>
                    <StyledButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      startIcon={loading ? <CircularProgress size={20} /> : <SendArrowUpFill />}
                    >
                      Send
                    </StyledButton>
                  </Box>
                </form>
              </ChatInputContainer>
            </ChatContainer>)}
        </Grid>
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default LegalQueryAssistant;
