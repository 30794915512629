import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CardContent, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Link, List, ListItem, ListItemText, Popover, Snackbar, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import GenerateButton from '../../Components/Folders/Generate';
import Folders from '../../Components/Folders/Folders';
import CreateFolder from '../../Components/Folders/create';
import { CloudUpload, ShutterSpeed } from '@mui/icons-material';
import FolderView from '../../Components/Folders/Folder';
import folders from '../../Components/Folders/Data';
import FolderService from '../../Services/DocAI/FolderService';
import StyledButton from '../../Components/Folders/StyledButton';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../Services/Authentication/AuthContext';
import HistoryIcon from '@mui/icons-material/History';
import { styled } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

const MAX_FILE_SIZE_MB = 20;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
const MAX_FILE_COUNT = 5;

const CustomPopoverContent = styled(Card)(({ theme }) => ({
    backgroundColor: '#1e1e2f',
    color: '#ffffff',
    borderRadius: '20px',
    padding: '0px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
    //maxHeight: '300px',
    //overflowY: 'auto',
    //minWidth: '320px',
    maxWidth: '280px',
    border: '1px solid #303245',
}));

const RecentActionsTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '18px',
    color: '#ffffff',
    marginBottom: '10px',
    position: 'relative',
    justifyContent: 'center',
}));

const DownArrowIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
    position: 'relative',
    //bottom: 'px', // Adjust this value to control the position of the arrow
}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: '#2a2d3e',
    borderRadius: '10px',
    marginBottom: '5px',
    //padding: '15px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    '&:last-child': {
        marginBottom: 0,
    },
}));

// const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
//     backgroundColor: '#1e1e2f',
//     color: '#ffffff',
//     borderRadius: '10px 10px 0 0',
//     padding: '5px 20px',
//     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
//     '& .MuiAccordionSummary-expandIconWrapper': {
//         color: '#ffffff',
//     },
// }));

const CustomAccordionSummary = styled(({ expanded, ...props }) => (
    <AccordionSummary {...props} />
))(({ theme, expanded }) => ({
    backgroundColor: '#320d52',
    color: '#ffffff',
    borderRadius: expanded ? '10px 10px 0 0' : '10px', // Remove bottom corners when expanded
    padding: '5px 20px',
    boxShadow: expanded
        ? '0px 2px 4px rgba(0, 0, 0, 0.1)' // Subtle shadow when expanded
        : '0px 4px 10px rgba(0, 0, 0, 0.2)', // Deeper shadow when not expanded
    border: expanded ? '1px solid rgba(0, 0, 0, 0.2)' : 'none', // Border for expanded state
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: '#ffffff',
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: '#4a0f733b',
    color: '#ffffff',
    borderRadius: '0px 0px 10px 10px',
    padding: '10px',
    maxHeight: '300px',
    overflowY: 'auto',
}));

// const CustomListItem = styled(ListItem)(({ theme }) => ({
//     backgroundColor: '#3a3d4e',
//     borderRadius: '8px',
//     marginBottom: '10px',
//     padding: '15px',
//     boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
//     '&:last-child': {
//         marginBottom: 0,
//     },
// }));

function Workspace() {

    const { userDetails } = useAuth(useContext);

    const [anchorEl, setAnchorEl] = useState(true);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        // Automatically open the popover when the page loads
        setAnchorEl(document.querySelector('#recent-actions-title'));
    }, []);

    const [selectedFolder, setSelectedFolder] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [foldersData, setFoldersData] = useState([]);
    const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false);
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [selectedFilesForUpload, setSelectedFilesForUpload] = useState([]);


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('warning');

    const [userName, setUserName] = useState();
    const navigate = useNavigate();

    const fetchFolders = async () => {
        try {
            const response = await FolderService.GetFoldersByUser();
            if (!response) {
                return;
            }
            setFoldersData(response);

        } catch (error) {
            console.error('Error fetching folders:', error);
        }
    };

    const handleFolderSelect = (folder) => {
        if (selectedFolder && selectedFolder.id === folder.id) {
            setSelectedFolder(null);
            return;
        }
        setSelectedFolder(folder);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const updateFolders = () => {
        fetchFolders();
    }

    const handleFolderDialogOpen = () => {
        setIsFolderDialogOpen(true);
    };

    const handleFolderDialogClose = () => {
        setIsFolderDialogOpen(false);
        setSelectedFolder(null);
    };

    const handleSelectFolder = () => {
        if (!selectedFolder) {
            setSnackbarMessage('Select folder first to upload file');
            setSnackbarOpen(true);
            return;
        }
        setIsFolderDialogOpen(false);
        setIsUploadDialogOpen(true);
    };


    const handleUploadDialogOpen = () => {
        if (!selectedFolder) {
            handleFolderDialogOpen();
            return;
        }

        if (!selectedFolder) {

            setSnackbarMessage('Select folder first to upload file');
            setSnackbarOpen(true);
            return;
        }
        setIsUploadDialogOpen(true);
    };

    const handleUploadDialogClose = () => {
        setIsUploadDialogOpen(false);
        setSelectedFilesForUpload([]);
    };

    const handleFileChange = (event) => {

        const files = Array.from(event.target.files);

        if (files.length > MAX_FILE_COUNT) {
            setSnackbarMessage(`You can only select up to ${MAX_FILE_COUNT} files at a time.`);
            setSnackbarOpen(true);
            return;
        }

        const largeFiles = files.filter(file => file.size > MAX_FILE_SIZE_BYTES);

        if (largeFiles.length > 0) {
            setSnackbarMessage(`Some files exceed the ${MAX_FILE_SIZE_MB}MB limit. Please select smaller files.`);
            setSnackbarOpen(true);
            return;
        }

        setSelectedFilesForUpload(files);
    };

    const handleFileRemove = (index) => {
        setSelectedFilesForUpload(prevFiles => prevFiles.filter((_, i) => i !== index));
    };


    const handleUploadFile = async () => {
        if (selectedFilesForUpload === 0) {
            setSnackbarMessage('No files selected');
            setSnackbarOpen(true);
            return;
        }

        const formData = new FormData();
        selectedFilesForUpload.forEach((file) => formData.append('files', file));

        try {

            const response = await FolderService.uploadMultipleFiles(formData, selectedFolder.id);
            handleUploadDialogClose();
            setSeverity('success')
            setSnackbarMessage('File(s) uploaded successfully.');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Failed to upload file', error);
            setSnackbarMessage('Failed to upload file');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage('');
        setSeverity('warning')
    };

    useEffect(() => {
        fetchFolders();

        if (userDetails && !userName) {
            setUserName(userDetails.username);
        }

        fetchRecentActions();
    
        // Optionally set an interval to refresh the list every X seconds (e.g., 60 seconds)
        const interval = setInterval(() => {
          fetchRecentActions();
        }, 60000); // Refresh every 60 seconds
    
        // Cleanup the interval when the component unmounts
        return () => clearInterval(interval);

    }, [userDetails]);

    const [recentActions, setRecentActions] = useState([]);
    const fetchRecentActions = async () => {
    try {

        console.log(userDetails);
        //https://mtt2dp3ze6.execute-api.us-east-1.amazonaws.com/dev/audit/user/{userId}
        //http://lassistusrmgt2sep-1056336277.us-east-1.elb.amazonaws.com/api/UserManagement/api/audit/user/
        const response = await axios.get('https://mtt2dp3ze6.execute-api.us-east-1.amazonaws.com/dev/audit/user/'+userDetails.userId);
        setRecentActions(response.data);
    } catch (error) {
      console.error('Error fetching recent actions:', error);
    }
  };

  const [showAll, setShowAll] = useState(false); 

  const filteredActions = recentActions
  .filter((action) =>
    action.ActivityType.toLowerCase().includes('document') || 
    action.Details.toLowerCase().includes('document')
  )
  .reverse();

  const actionsToShow = showAll ? filteredActions : filteredActions.slice(0, 3);

    const TileButton = ({ title, icon, onClick }) => (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                background: '#4a0f73',
                color: '#fff',
                borderRadius: '8px',
                padding: '16px',
                marginBottom: '16px',
                width: '150px',
                height: '100px',
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }}
            onClick={onClick}
        >
            {icon}
            <Typography variant="body1" sx={{ marginTop: 1 }}>
                {title}
            </Typography>
        </Box>
    );

    return (
        <Box display="flex" flexDirection="column" className="dashboard">
            <Box flexGrow={1} p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Typography paddingLeft='25px' display='flex' justifyContent='left' variant="h5"> Welcome {userName ? userName : 'User'}</Typography>
                        <Box
                            className="Folders flex"
                            md={8}
                            whiteSpace={3}
                            flexWrap="wrap"
                            columnGap={7}
                            style={{
                                maxHeight: '75vh',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                scrollbarColor: 'rgba(255, 255, 255, 0.5) transparent',
                                justifyContent: foldersData.length === 0 ? 'center' : undefined,
                                alignItems: foldersData.length === 0 ? 'center' : undefined,
                            }}
                        >
                            {foldersData.length === 0 ? (
                                
                                <Box textAlign="center">
                                    <Typography variant="h6" gutterBottom style={{ color: 'white' }}>
                                        No folders found
                                    </Typography>
                                    <Typography variant="body1" gutterBottom style={{ color: 'white' }}>
                                        Create a folder to store your documents.
                                    </Typography>
                                    <CreateFolder onCreateFolder={updateFolders} />
                                </Box>
                            ):(
                                foldersData.map(folder => (
                                <div style={{ borderRadius: '10px' }} key={folder.id}>
                                    <FolderView onFolderUpdate={fetchFolders} onFolderSelect={handleFolderSelect} folder={folder} onSelectedFolderChange={selectedFolder && selectedFolder.id === folder.id} />
                                </div>
                            ))
                        )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} >

                        {/* <Box
            sx={{
                background: 'transparent',
                borderRadius: '10px',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%', // Full width
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    marginBottom: '16px',
                }}
            >
                <ShutterSpeed sx={{ marginRight: 1 }} />
                <Typography variant="h6">Quick Actions</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    flexWrap: 'wrap', // Allow wrapping to the next line
                    width: '100%', // Full width for the tile area
                }}
            >
                <TileButton
                    title="Upload Document"
                    icon={<CloudUpload fontSize="large" />}
                    onClick={handleUploadDialogOpen}
                    color="#4caf50" // Green
                />
                <TileButton
                    title="Summarize Document"
                    icon={<SummarizeIcon fontSize="large" />}
                    onClick={() => navigate('/summarize-doc')}
                    color="#2196f3" // Blue
                />
                <TileButton
                    title="Generate Document"
                    icon={<NoteAddOutlined fontSize="large" />}
                    onClick={() => navigate('/legal-assistant')}
                    color="#ff9800" // Orange
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '16px',
                    }}
                >
                    <CreateFolder onCreateFolder={updateFolders} color="#9c27b0" />
                </Box>
            </Box>
        </Box> */}
                        <Accordion expanded={true} sx={{ background: 'transparent'}}>
                            <CustomAccordionSummary
                                expanded
                                // expandIcon={<ArrowDropDownIcon />}
                                aria-controls="quick-actions-content"
                                id="quick-actions-header"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    padding: 0
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%'
                                    }}
                                >
                                    <ShutterSpeed sx={{ marginRight: 1 }} />
                                    <Typography variant='h6'>Quick Actions</Typography>
                                </Box>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                                <Box p={2} xs={12} md={8} display="flex" flexDirection="column" alignItems="center">
                                    <StyledButton
                                        variant="contained"
                                        sx={{ background: '#4a0f73' }}
                                        startIcon={<CloudUpload />}
                                        style={{ marginBottom: 16 }}
                                        onClick={handleUploadDialogOpen}
                                    >
                                        Upload Document
                                    </StyledButton>

                                    <StyledButton startIcon={<SummarizeIcon />} variant="contained" sx={{ background: '#4a0f73' }} onClick={() => navigate('/summarize-doc')} style={{ marginBottom: 16 }}>
                                        Summarize Document
                                    </StyledButton>

                                    <GenerateButton />
                                    <CreateFolder onCreateFolder={updateFolders} />
                                </Box>
                            </CustomAccordionDetails>
                        </Accordion>
                        <Accordion sx={{ background: 'transparent'}}>
                            <CustomAccordionSummary
                                expanded
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    padding: '0px 0px 0px 0px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        flexGrow: 1,
                                    }}
                                >
                                    <HistoryIcon sx={{ marginRight: 1 }} />
                                    <Typography variant='h6'>Recent Actions</Typography>
                                </Box>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                            {actionsToShow.length === 0 ? (
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: 'white',
                                            textAlign: 'center',
                                            padding: '10px',
                                        }}
                                    >
                                        No recent actions
                                    </Typography>
                                ) : (
                                <>
                                    <List
                                    sx={{
                                    maxHeight: '150px',
                                    overflowY: 'auto',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: 'rgba(255, 255, 255, 0.5) transparent',
                                    }}
                                >
                                    {actionsToShow.map((action, index) => (
                                    <CustomListItem key={index} style={{ background: '#4a0f7361' }}>
                                        <Tooltip title={action.Details + ' - ' + new Date(action.Timestamp).toLocaleString()} placement="top">
                                        <Typography
                                            noWrap
                                            sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '200px',
                                            }}
                                        >
                                            {action.Details} - {new Date(action.Timestamp).toLocaleString()}
                                        </Typography>
                                        </Tooltip>
                                    </CustomListItem>
                                    ))}
                                </List>
                                {filteredActions.length > 3 && (
                                    <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => setShowAll(!showAll)}
                                    sx={{
                                        marginTop: 'px',
                                        display: 'block',
                                        color: 'white',
                                        '&:hover': {
                                          backgroundColor: 'transparent',
                                          textDecoration: 'underline',
                                        }
                                      }}
                                    >
                                    {showAll ? 'See less...' : 'See more...'}
                                    </Link>
                                )}
                                </>)}
                                </CustomAccordionDetails>
                        </Accordion>
                        {/* </Box> */}
                    </Grid>
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={severity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Dialog open={isFolderDialogOpen} onClose={handleFolderDialogClose} aria-labelledby="customized-dialog-title">
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Select Folder</DialogTitle>
                    <DialogContent style={{ minWidth: '500px', maxHeight: '300px' }} dividers>
                        <List>
                        {foldersData.length === 0 && 
                            <Typography>
                                Please create folder to start uploading documents!
                            </Typography>
                        }
                            
                            {foldersData.map((folder) => (
                                <ListItem divider
                                    key={folder.id}
                                    button
                                    onClick={() => handleFolderSelect(folder)}
                                    sx={{
                                        backgroundColor: selectedFolder && selectedFolder.id === folder.id ? '#f0f0f0' : 'transparent',
                                        borderRadius: '4px',
                                        mb: '8px',
                                        '&:hover': {
                                            backgroundColor: '#e0e0e0',
                                        },
                                    }}
                                >
                                    <FolderIcon />
                                    <ListItemText primary={folder.name} sx={{ ml: 1 }} />
                                </ListItem>
                            ))}

                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFolderDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSelectFolder} color="primary">
                            Select
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog 
                    open={isUploadDialogOpen} 
                    onClose={handleUploadDialogClose}
                    fullWidth
                    maxWidth="sm"
                    PaperProps={{
                        style: { borderRadius: 15, padding: '5px' }
                    }}
                >
                    <DialogTitle>Upload Documents</DialogTitle>
                    <DialogContent dividers>
                        <>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mt={3}
                            mb={3}
                            style={{
                                border: '2px dashed #aaa',
                                borderRadius: '12px',
                                width: '150px',
                                height: '150px',
                                margin: '0 auto',
                                padding: '20px',
                            }}
                        >
                            {/* <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                
                            /> */}
                            <input id="file-upload" style={{ border: '', display: 'none'}} type="file" onChange={handleFileChange} multiple />
                            <label htmlFor="file-upload">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <CloudUploadIcon style={{ fontSize: '4rem', color: '#1976d2' }} />
                                </IconButton>
                            </label>
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            Please select a document to upload. Supported file formats are:
                        </Typography>
                        <ul>
                            <li>PDF</li>
                            <li>Images (JPG, JPEG, PNG)</li>
                            <li>Text Documents (TXT, DOCX, DOC)</li>
                        </ul>
                    </>

                    </DialogContent>
                    <DialogContent style={{ minWidth: '100px' }} dividers>


                        {selectedFilesForUpload && selectedFilesForUpload.length > 0 && (
                            <List>
                                {selectedFilesForUpload.map((file, index) => (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemove(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText primary={file.name} secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUploadDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleUploadFile} color="primary">
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default Workspace;
