import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import FolderContextMenu from './FolderContext';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Delete, FolderOpen, Info, Margin, MoreVertOutlined, DriveFileRenameOutline, Folder } from '@mui/icons-material';
import FolderService from '../../Services/DocAI/FolderService';
import RenameFolderPopup from './RenameFolderPopup';
import CustomSnackbar from '../SnackBar';
import { height } from '@mui/system';

const FolderView = ({ onFolderUpdate, onFolderSelect, folder, onSelectedFolderChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFolder, setOpenFolder] = useState(null);
  const [isRenameFolderDialogOpen, setIsRenameFolderDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [renameFolderName, setRenameFolderName] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [isDeleteFolderDialogOpen, setIsDeleteFolderDialogOpen] = useState(false);
  const [folderMetadata, setFolderMetadata] = useState({});
  const [isShowDetailsDialogOpen, setIsShowDetailsDialogOpen] = useState(false);
  const [folderSelected, setFolderSelected] = useState(onSelectedFolderChange);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleChange = () => {
    setIsOpen(!isOpen);
    onFolderSelect(folder);
    setSelectedFolder(folder);
  }
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const navigate = useNavigate();


  const handleContextMenu = (event, folder) => {
    event.preventDefault();
    setSelectedFolder(folder);
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleRename = async (event) => {
    event.preventDefault();
    if(!renameFolderName) {
      showSnackbar('Folder name cannot be empty.', 'error');
      return;
    }

    handleClose();
    // Logic to handle document generation
    console.log('Rename Folder');
    
    setLoading(true);
    try {
      console.log("New name: " + renameFolderName);
      const response = await FolderService.upateFolderMetadata(selectedFolder.id, renameFolderName);
      showSnackbar(`Folder renamed successfully!`, 'info');
      handleRenameFolderClose();
      onFolderUpdate();

    } catch (error) {
      showSnackbar('Error renaming folder.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleShoeDetails = () => {
    handleClose();
  };

  const handleOpenFolder = () => {
    handleClose();
    navigate(`/folder/${selectedFolder.id}`);
  };

  const handleDoubleClick = (folder) => {
    navigate(`/folder/${folder.id}`);
  };

  const handleRenameFolderOpen = (folder) => {
    setRenameFolderName(folder.name);
    setIsRenameFolderDialogOpen(true);
    handleClose();
  };


  const handleRenameFolderClose = () => {
    setIsRenameFolderDialogOpen(false);
    setRenameFolderName('');
  };

  const handleDeleteFolderOpen = () => {
    setIsDeleteFolderDialogOpen(true);
    handleClose();
  };

  const handleDeleteFolderClose = () => {
    setIsDeleteFolderDialogOpen(false);
  };

  const handleDeleteFolder = async (event) => {
    handleClose();

    // Logic to handle document generation
    console.log('Delete Folder');
    event.preventDefault();
    setLoading(true);
    try {
      const response = await FolderService.deleteFolder(selectedFolder.id);
      setStatusMessage(`Folder Deleted Successfully!`);
      handleDeleteFolderClose();
      onFolderUpdate();

    } catch (error) {
      setStatusMessage('Error renaming folder.');
    } finally {
      setLoading(false);
    }
  };

  const fetchFolderMetadata = async (event, folderId) => {

    handleClose();

    // Logic to handle document generation
    console.log('Delete Folder');
    event.preventDefault();
    setLoading(true);
    try {
      const response = await FolderService.getFolderMetadata(folderId);
      setStatusMessage(`Fetch foldermetadata success!`);
      setFolderMetadata(response);
      handleDeleteFolderClose();
      onFolderUpdate();

    } catch (error) {
      console.error('Failed to fetch folder metadata', error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowDetailsOpen = (e, folder) => {
    fetchFolderMetadata(e, folder.id);
    setIsShowDetailsDialogOpen(true);
  };

  const handleShowDetailsClose = () => {
    setIsShowDetailsDialogOpen(false);
  };

  useEffect(() => {
    setSelectedFolder(onSelectedFolderChange);
  }, []);

  const getRandomBlueColor = () => {
    const shadesOfBlue = [
        "#1E90FF", "#00BFFF", "#4682B4", "#4169E1", "#5F9EA0", "#6495ED"
    ];
    return shadesOfBlue[Math.floor(Math.random() * shadesOfBlue.length)];
};

  //, backgroundColor: targetFolder && targetFolder.id === folder.id ? '#f0f0f0' : 'transparent'
  return (

    <div >

{/* <Box
    className="Folder"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="space-between"
    onClick={handleChange}
    onContextMenu={selectedFolder && selectedFolder.id === folder.id ? (e) => handleContextMenu(e, folder) : () => {}}
    onDoubleClick={() => handleDoubleClick(folder)}
    style={{
        cursor: 'pointer',
        background: getRandomBlueColor(), // Random blue background
        userSelect: 'none',
        borderRadius: '0px',
        padding: '15px',
        margin: '4px',
        boxShadow: onSelectedFolderChange ? "0px 4px 12px rgba(0, 0, 0, 0.2)" : "0px 2px 8px rgba(0, 0, 0, 0.1)",
        transition: 'background-color 0.3s, box-shadow 0.3s, border 0.3s',
        height: '130px',
        width: '263px',
        position: 'relative',
        overflow: 'hidden',
        border: onSelectedFolderChange ? "2px solid #ffffff" : "none", // White border on selection
    }}
>
    {/* Folder Icon Styled as Container
    <Folder
        style={{
            fontSize: '50px', // Smaller icon size
            color: "white", // White icon color
            marginBottom: '10px',
            border: "2px solid white", // White border for icon
            borderRadius: '50%', // Circular border around the icon
            padding: '5px', // Padding for the icon
        }}
    /> 

    
    <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    style={{ width: '100%', textAlign: 'center', flex: 1 }} // Ensures centering
>
    
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
    >
        <Folder
            style={{
                fontSize: '50px', // Smaller icon size
                color: "white", // White icon color
                marginBottom: '10px',
                border: "2px solid white", // White border for icon
                borderRadius: '50%', // Circular border around the icon
                padding: '5px', // Padding for the icon
            }}
        />
        
        <Tooltip title={folder.name} placement="bottom">
            <Typography
                style={{
                    fontSize: '1rem',
                    color: "white", // White text color
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginBottom: '5px',
                }}
            >
                {folder.name}
            </Typography>
        </Tooltip>
    </Box>


        <Box style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            <IconButton
                style={{
                    padding: 0,
                    color: "white", // White color for the icon
                }}
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={(e) => handleContextMenu(e, folder)}
            >
                <MoreVertOutlined style={{ color: "white" }} />
            </IconButton>
        </Box>
    </Box>
</Box>

 */}

      <Box
        className="Folder"
        display="flex"
        flexDirection="column"  // Stack elements vertically
        alignItems="center"     // Center horizontally
        justifyContent="center" // Center vertically within the Box
        onClick={handleChange}
        onContextMenu={selectedFolder && selectedFolder.id === folder.id ? (e) => handleContextMenu(e, folder) : () => {}}
        onDoubleClick={() => handleDoubleClick(folder)}
        style={{
          cursor: 'context-menu',
          background: onSelectedFolderChange ? "linear-gradient(180deg, #FFEB3B 0%, #FF9800 100%)" : "", // Gradient background
          userSelect: 'none',
          borderRadius: '8px',
          padding: '10px',
          margin: '8px 0',
          boxShadow: onSelectedFolderChange ? "0px 4px 12px rgba(0, 0, 0, 0.1)" : "",
          transition: 'background-color 0.3s, box-shadow 0.3s',
          height: '145px',  // Fixed height
          width: '150px',   // Fixed width for better alignment
          position: 'relative',
          overflow: 'hidden',  // Ensures content doesn't overflow the fixed height
        }}
      >
        
        <Folder
          style={{
            fontSize: '100px',
            color: onSelectedFolderChange ? "#ffffff" : "rgb(255 240 103)",
            marginBottom: '5px', // Space between icon and text
          }}
        />

        
        <Box
          justifySelf='center'
          display="flex"
          flexDirection="row"    // Align name and icon horizontally
          alignItems="center"    // Vertically center the text and icon together
          justifyContent="center" // Horizontally center the content
          style={{ width: '90%', textAlign: 'center' }}
        >
          <Tooltip title={folder.name} placement="bottom">
            <Typography
              style={{
                fontSize: '0.9rem',
                color: onSelectedFolderChange ? "#ffffff" : "#ffffff",
                whiteSpace: 'nowrap',  // Prevents wrapping of the folder name
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginRight: '2px',    // Add some space between the text and the icon
              }}
            >
              {folder.name}
            </Typography>
          </Tooltip>

          <IconButton
            style={{
              padding: 0,           // Remove extra padding around the icon
              color: onSelectedFolderChange ? "#ffffff" : "#000000",
            }}
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={(e) => handleContextMenu(e, folder)}
          >
            <MoreVertOutlined style={{ color: '#ffffff' }} />
          </IconButton>
        </Box>
      </Box>


{/* <Box 
  className="Folder" 
  display='flex' 
  flexDirection='row' 
  alignItems="center"
  justifyContent="space-between"
  md={2} 
  flexWrap="wrap"
  onClick={handleChange}
  onContextMenu={(e) => handleContextMenu(e, folder)}
  onDoubleClick={() => handleDoubleClick(folder)}
  style={{ 
    cursor: 'context-menu', 
    backgroundColor: onSelectedFolderChange ? "#4a0f73" : "", 
    userSelect: 'none',
    borderRadius: '8px',
    padding: '8px',
    margin: '8px 0',
    boxShadow: onSelectedFolderChange ? "0px 4px 12px rgba(0, 0, 0, 0.1)" : "",
    transition: 'background-color 0.3s, box-shadow 0.3s',
    height: '100px',  // Fixed height
    overflow: 'hidden'  // Ensures content doesn't overflow the fixed height
  }}
>
  <FontAwesomeIcon 
    size='30' 
    icon={faFolder} 
    style={{ marginRight: '10px', color: onSelectedFolderChange ? "#ffffff" : "#4a0f73" }} 
  />
  <h5 style={{ 
    margin: 0, 
    color: onSelectedFolderChange ? "#ffffff" : "#000000", 
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis',
    flexGrow: 1,
    maxHeight: '48px',  // Adjust max height to fit within the fixed container
    lineHeight: '1.2em'
  }}>
    {folder.name}
  </h5>

  <IconButton 
    style={{ 
      margin: '0px', 
      color: onSelectedFolderChange ? "#ffffff" : "#000000" 
    }}
    aria-label="more"
    id="long-button"
    aria-haspopup="true"
    onClick={(e) => handleContextMenu(e, folder)}
  >
    <MoreVertOutlined />
  </IconButton>
</Box> */}




      <Menu
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
        }
        open={contextMenu !== null}
        onClose={handleClose}
        disableScrollLock={true}
      >
        <MenuItem onClick={handleOpenFolder}>
          <ListItemIcon>
            <FolderOpen />
          </ListItemIcon>
          <ListItemText primary="Open" />
        </MenuItem>
        <MenuItem onClick={() => { handleRenameFolderOpen(selectedFolder); }}>
          <ListItemIcon>
            <DriveFileRenameOutline />
          </ListItemIcon>
          <ListItemText primary="Rename" />
        </MenuItem>
        <MenuItem onClick={(e) => handleShowDetailsOpen(e, selectedFolder)}>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText primary="Show Details" />
        </MenuItem>
        <MenuItem onClick={handleDeleteFolderOpen}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      <Dialog open={isRenameFolderDialogOpen} onClose={handleRenameFolderClose}>
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Folder Name"
            fullWidth
            value={renameFolderName}
            onChange={(e) => setRenameFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameFolderClose} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => handleRename(e)} color="primary">
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeleteFolderDialogOpen} onClose={handleDeleteFolderClose}>
        <DialogTitle>Delete Folder</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this folder?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteFolderClose} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => handleDeleteFolder(e)} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isShowDetailsDialogOpen} onClose={handleShowDetailsClose}>
        <DialogTitle>Folder Details</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Name: {folderMetadata.name}</Typography>
          <Typography variant="body1">Created At: {folderMetadata.createdAt}</Typography>
          <Typography variant="body1">Number of Files: </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShowDetailsClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />

    </div>

  );
}

export default FolderView;
